import React, { useMemo } from 'react';

const formatCash = (n:number) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export type Ticker = {
    symbol: string;
    volume: number;
    interval: string;
    price: number;
    exchange: string;
    id: string;
    timestamp: string;
    tier: number;
    marketCap: number;
}

function Row(props: {ticker: Ticker}) {
    const {ticker} = props
    const {symbol, exchange} = ticker;

    const url = useMemo(() => {
        if (exchange === 'Binance') {
            return `https://www.binance.com/en/trade/${symbol}`;
        } else {
            return `https://www.gate.io/en/trade/${symbol}`;
        }
    }, [exchange, symbol]);

    const s = symbol.split('_')
    return (
        <tr className="tv-data-table__row tv-data-table__stroke tv-screener-table__result-row" >
            <td className="tv-data-table__cell apply-common-tooltip tv-screener-table__cell tv-screener-table__cell--left tv-screener-table__cell--with-marker"
                title="" data-field-key="name">
                <div className="tv-screener-table__symbol-container ">
                    <div className="tv-screener-table__symbol-addition">
                        <div className="uiMarker-1PBS_wt6 tv-screener-table__marker">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="14" height="12" fill="currentColor" focusable="false" preserveAspectRatio="none">
                                <path d="M14 12l-4-6 4-6H0v12z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="tv-screener-table__symbol-container-description">
                        <a className="tv-screener__symbol apply-common-tooltip" href={url} target='_blank'>{ticker.symbol}</a>
                        <span className="tv-data-mode tv-data-mode--for-screener apply-common-tooltip tv-data-mode--realtime tv-data-mode--realtime--for-screener" title="Real-time">R</span>
                        <span className="tv-screener__description">{s[0]} / {s[1]} COIN </span>
                    </div>
                </div>
            </td>
            <td className="tv-data-table__cell tv-screener-table__cell tv-screener-table__cell--with-marker" title="" data-field-key="close">
                <span>{ticker.price}</span>
            </td>
            <td className="tv-data-table__cell tv-screener-table__cell tv-screener-table__cell--with-marker" title="" data-field-key="volume">{formatCash(ticker.volume)}</td>
            <td className="tv-data-table__cell tv-screener-table__cell tv-screener-table__cell--with-marker" title="" data-field-key="volume">{formatCash(ticker.marketCap)}</td>
            <td className="tv-data-table__cell tv-screener-table__cell tv-screener-table__cell--left tv-screener-table__cell--with-marker" title="" data-field-key="Recommend.All">
                <span className="tv-screener-table__signal tv-screener-table__signal--strong-buy">{ticker.interval}</span>
            </td>
            {/* Exchange */}
            <td className="tv-data-table__cell tv-screener-table__cell tv-screener-table__cell--left tv-screener-table__cell--with-marker" title="" data-field-key="Recommend.All">
                <span className="tv-screener-table__signal tv-screener-table__signal--strong-buy">{ticker.exchange}</span>
            </td>
        </tr>
    );
}

export default Row;
