import React, { useEffect, useState } from 'react';
import './App.css';
import TableHeader from './components/Ticker/TableHeader';
import Row, { Ticker } from './components/Ticker/Ticker';
import Select from 'react-select';

const tierOptions = [
  { value: '', label: "Chọn Nhóm - điều kiện"},
  { value: '1', label: "Nhóm 1 - Nhóm breakout"},
  { value: '2', label: "Nhóm 2 - Hỗ trợ đường nâu"},
  { value: '3', label: "Nhóm 3 - Hỗ trợ đường tím"},
  { value: '4', label: "Nhóm 4 - Đường BB Giữa (1W)"},
  { value: '5', label: "Nhóm 5 - Đã Breakout - có lực tăng tiếp"}
]

const volumeOptions = [
  { value: "", label: "Chọn Volume"},
  { value: "50000", label: "50k - 200k"},
  { value: "200000", label: "200k - 500k"},
  { value: "500000", label: "500k - 1M"},
  { value: "1000000", label: "1M - 10M"},
  { value: "10000000", label: "10M - 50M"},
  { value: "50000000", label: "50M - 100M"},
  { value: "100000000", label: ">= 100M"},
]

const marketCapOptions = [
  { value: "", label: "Chọn Market Cap"},
  { value: "0", label: "0 - 500M"},
  { value: "500000000", label: "500M - 1B"},
  { value: "1000000000", label: ">= 1B"},
]

function App() {
  const [symbols, setSymbols] = useState([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<{value: string, label: string} | null>(null);
  const [selectedTier, setSelectedTier] = useState<{value: string, label: string} | null>(null);
  const [selectedVolume, setSelectedVolume] = useState<{value: string, label: string} | null>(null);
  const [selectedMarketCap, setSelectedMarketCap] = useState<{value: string, label: string} | null>(null);
  const [selectedExchange, setSelectedExchange] = useState<{value: string, label: string} | null>(null);

  useEffect(() => {
    let params = '';
    if(selectedVolume || selectedTier || selectedTimeFrame || selectedMarketCap || selectedExchange) {
      params += '?';
    }

    if(selectedTier?.value) {
      params += 'tier=' + selectedTier.value

      if (selectedTier.value === '4') {
        params += '&timeframe=1w'
      }
    }

    if (selectedVolume?.value) {
      if(params !== '?') {
        params += '&';
      }
      params += 'volume='+selectedVolume.value
    }

    if (selectedTimeFrame?.value) {
      if(params !== '?') {
        params += '&';
      }
      params += 'timeframe='+selectedTimeFrame.value
    }

    if (selectedMarketCap?.value) {
      if(params !== '?') {
        params += '&';
      }
      params += 'marketcap='+selectedMarketCap.value
    }

    if (selectedExchange?.value) {
      if(params !== '?') {
        params += '&';
      }
      params += 'exchange='+selectedExchange.value
    }

    fetch("http://174.138.31.124:3001/today" + params)
      .then(res => res.json())
      .then(
        (result) => {
          setSymbols(result)
        },
        (error) => {
          console.log(error)
        }
      )
  }, [selectedTier, selectedVolume, selectedTimeFrame, selectedMarketCap, selectedExchange])

  const downloadSymbols = () => {
    const element = document.createElement("a");

    const fileContent = symbols.map((symbol: Ticker) => {
      // return Exchange:Symbol
      // If exchange is GateIO, we need to remove _ inside symbol
      return `${symbol.exchange.toUpperCase()}:${
        symbol.exchange === 'GateIO' ? symbol.symbol.replace('_', '') : symbol.symbol
      }`
    }).join(','); 

    const file = new Blob([fileContent], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `signals-${selectedTimeFrame?.value || 'all'}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }


  return (
    <div className="App">
      <div className="tv-content">
        <div className="tv-screener__standalone-title-wrap">
          <h1 className="tv-screener__standalone-title">Crypto Screener</h1>
        </div>
      </div>

      <div className="tv-site-widget__body tv-screener__standalone-wrap">
        <div className="tv-site-widget__body tv-screener__standalone-wrap">
        <div id="js-screener-container" className="tv-screener tv-screener--standalone tv-screener--sticky-header" >
          <div className="tv-screener-toolbar tv-screener-toolbar--standalone  tv-screener-toolbar--standalone_sticky">
            <div className="tv-screener-toolbar__favorites">
              <div style={{marginRight: 10, marginLeft: 10}}>
                <Select
                    defaultValue={selectedTier}
                    onChange={(newValue, _) => {
                      if (newValue?.value === '') { 
                        setSelectedTier(null);
                        return;
                      }
                      setSelectedTier(newValue);
                    }}
                    options={tierOptions}
                    placeholder="Chọn Nhóm - điều kiện"
                />
              </div>
              <div style={{marginRight: 10, marginLeft: 10}}>
                <Select
                    defaultValue={selectedVolume}
                    onChange={(newValue, _) => {
                      if (newValue?.value === '') { 
                        setSelectedVolume(null);
                        return;
                      }
                      setSelectedVolume(newValue);
                    }}
                    options={volumeOptions}
                    placeholder="Chọn điều kiện - Volume"
                />
              </div>

              <div style={{marginRight: 10, marginLeft: 10}}>
                <Select
                    defaultValue={selectedMarketCap}
                    onChange={(newValue, _) => {
                      if (newValue?.value === '') { 
                        setSelectedMarketCap(null);
                        return;
                      }
                      setSelectedMarketCap(newValue);
                    }}
                    options={marketCapOptions}
                    placeholder="Chọn điều kiện - Market Cap"
                />
              </div>

              <div style={{marginRight: 10, marginLeft: 10}}>
                <Select
                    defaultValue={selectedTimeFrame}
                    onChange={(newValue, _) => {
                      if (newValue?.value === '') { 
                        setSelectedTimeFrame(null);
                        return;
                      }
                      setSelectedTimeFrame(newValue);
                    }}
                    options={[
                      { value: '', label: "Chọn Time Frame"},
                      { value: '4h', label: "4h"},
                      { value: '12h', label: "12h"},
                      { value: '1d', label: "1D"},
                      { value: '3d', label: "3D"},
                      { value: '1w', label: "1W"},
                    ]}
                    placeholder="Chọn Time Frame"
                />
              </div>
              {/* Select Exchange */}
              <div style={{marginRight: 10, marginLeft: 10}}>
                <Select
                    defaultValue={selectedExchange}
                    onChange={(newValue, _) => {
                      if (newValue?.value === '') { 
                        setSelectedExchange(null);
                        return;
                      }
                      setSelectedExchange(newValue);
                    }}
                    options={[
                      { value: '', label: "Chọn Sàn"},
                      { value: 'Binance', label: "Binance"},
                      { value: 'GateIO', label: "Gate"},
                    ]}
                    placeholder="Chọn Sàn"
                />
              </div>
              <div style={{marginRight: 10, marginLeft: 10}}>
                <button className="tv-screener-toolbar__download" onClick={downloadSymbols} style={{
                  backgroundColor: 'rgb(0, 123, 255)',
                  color: 'white',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}>
                  <span className="tv-screener-toolbar__download-icon"></span>
                  <span className="tv-screener-toolbar__download-text">Tải xuống</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        <table className="tv-data-table tv-screener-table tv-screener-table--fixed">
          <TableHeader total={symbols?.length || 0}/>
          <tbody className="tv-data-table__tbody">
            {
              symbols && symbols.length ? symbols.map((symbol: Ticker) => {
                return <Row ticker={symbol} key={symbol.id}/>
              }) : null
            }
          </tbody>
          
        </table>
        
      </div>
    </div>
  );
}

export default App;
