import React from 'react';

function TableHeader(props: {total: number}) {
    const {total} = props;
    return <thead className="tv-data-table__thead tv-screener-table__thead tv-screener-table__thead--regular">
    <tr className="tv-data-table__thead-row tv-data-table__stroke tv-screener-table__head">
        <th data-field="name"
            className="tv-data-table__th tv-data-table__cell js-tv-data-table-th js-tv-data-table-th-name tv-data-table__sortable tv-screener-table__sortable tv-screener-table__th js-draggable">
            <div className="tv-screener-table__head-wrap js-head-wrap" data-field="name">
                <div className="tv-screener-table__head-left js-head-left tv-screener-table__head-left--name-col">
                    <div className="tv-screener-table__head-left--text">
                        <div className="js-head-title tv-screener-table__head-left--title-three-lines">Ticker</div>
                        <div className="uiMarker-1PBS_wt6 tv-screener-table__head-left--marker"><svg
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="14" height="12"
                                fill="currentColor" focusable="false" preserveAspectRatio="none">
                                <path d="M14 12l-4-6 4-6H0v12z"></path>
                            </svg></div>
                    </div>
                    <div className="js-field-total tv-screener-table__field-value--total">{total} matches</div>
                    </div>
            </div>
        </th>
        <th className="tv-data-table__th tv-data-table__cell js-tv-data-table-th js-tv-data-table-th-Recommend_All tv-data-table__sortable tv-screener-table__sortable tv-screener-table__th js-draggable">
            <div className="tv-screener-table__head-wrap js-head-wrap" data-field="Recommend.All">
                <div className="tv-screener-table__head-left js-head-left">
                    <div className="tv-screener-table__head-left--text">
                        <div className="js-head-title tv-screener-table__head-left--title-three-lines">Price</div>
                    </div>
                </div><i data-field="Recommend.All" className="js-filter-button tv-screener-table__filter-button"><svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 22" width="19" height="22" fill="none">
                        <path fill="currentColor" fillRule="evenodd"
                            d="M5 1v9.13a4 4 0 0 1 0 7.75V21a1 1 0 1 1-2 0v-3-.12a4 4 0 0 1 0-7.75V1a1 1 0 0 1 2 0zM2 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm12-9.88V1a1 1 0 1 1 2 0v3.12a4 4 0 0 1 0 7.75V21a1 1 0 1 1-2 0v-9.13a4 4 0 0 1 0-7.75zM15 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z">
                        </path>
                    </svg></i><span className="tv-screener-table__sort js-table-sort "><svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 3" width="8" height="3">
                        <path d="M0 3 L4 0 L8 3 Z"></path>
                    </svg></span>
            </div>
        </th>
        <th data-field="volume"
            className="tv-data-table__th tv-data-table__cell js-tv-data-table-th js-tv-data-table-th-volume tv-data-table__sortable tv-screener-table__sortable tv-screener-table__th js-draggable">
            <div className="tv-screener-table__head-wrap js-head-wrap apply-common-tooltip common-tooltip-fixed"
                data-field="volume">
                <div className="tv-screener-table__head-left js-head-left">
                    <div className="tv-screener-table__head-left--text">
                        <div className="js-head-title tv-screener-table__head-left--title-three-lines">Vol</div>
                    </div>
                </div>
            </div>
        </th>

        <th data-field="volume"
            className="tv-data-table__th tv-data-table__cell js-tv-data-table-th js-tv-data-table-th-volume tv-data-table__sortable tv-screener-table__sortable tv-screener-table__th js-draggable">
            <div className="tv-screener-table__head-wrap js-head-wrap apply-common-tooltip common-tooltip-fixed"
                data-field="volume">
                <div className="tv-screener-table__head-left js-head-left">
                    <div className="tv-screener-table__head-left--text">
                        <div className="js-head-title tv-screener-table__head-left--title-three-lines">MarketCap</div>
                    </div>
                </div>
            </div>
        </th>

        <th data-field="volume"
            className="tv-data-table__th tv-data-table__cell js-tv-data-table-th js-tv-data-table-th-volume tv-data-table__sortable tv-screener-table__sortable tv-screener-table__th js-draggable">
            <div className="tv-screener-table__head-wrap js-head-wrap apply-common-tooltip common-tooltip-fixed"
                data-field="volume">
                <div className="tv-screener-table__head-left js-head-left">
                    <div className="tv-screener-table__head-left--text">
                        <div className="js-head-title tv-screener-table__head-left--title-three-lines">TimeFrame</div>
                    </div>
                </div>
            </div>
        </th>
        
        <th data-field="exchange"
            className="tv-data-table__th tv-data-table__cell js-tv-data-table-th js-tv-data-table-th-exchange tv-data-table__sortable tv-screener-table__sortable tv-screener-table__th js-draggable">
            <div className="tv-screener-table__head-wrap js-head-wrap" data-field="exchange">
                <div className="tv-screener-table__head-left js-head-left">
                    <div className="tv-screener-table__head-left--text">
                        <div className="js-head-title tv-screener-table__head-left--title-two-lines">Exchange</div>
                    </div>
                </div><i data-field="exchange"
                    className="js-filter-button tv-screener-table__filter-button tv-screener-table__filter-button--active"><svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 22" width="19" height="22" fill="none">
                        <path fill="currentColor" fillRule="evenodd"
                            d="M5 1v9.13a4 4 0 0 1 0 7.75V21a1 1 0 1 1-2 0v-3-.12a4 4 0 0 1 0-7.75V1a1 1 0 0 1 2 0zM2 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm12-9.88V1a1 1 0 1 1 2 0v3.12a4 4 0 0 1 0 7.75V21a1 1 0 1 1-2 0v-9.13a4 4 0 0 1 0-7.75zM15 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z">
                        </path>
                    </svg></i><span className="tv-screener-table__sort js-table-sort "><svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 3" width="8" height="3">
                        <path d="M0 3 L4 0 L8 3 Z"></path>
                    </svg></span>
            </div>
        </th>
    </tr>
</thead>
}

export default TableHeader;
